



import { Component, Vue } from 'vue-property-decorator';
import StepNavi from '../../../../components/contents/stepNavi/StepNavi.vue';

@Component({
  components: {
    StepNavi,
  },
})
export default class LoginForgetPasswordEditPasswordComplete extends Vue {
  private paramsName: any = '';
  private stepNavi: StepNaviData[] = [
    {
      title: 'ご本人確認',
    },
    {
      title: 'パスワード<br class="is-hidden-tablet">再設定',
    },
    {
      title: 'パスワード<br class="is-hidden-tablet">再設定完了',
    },
  ];

  private mounted() {
    const params: any = this.$route.params;
    if (typeof params !== 'undefined') {
      this.paramsName = this.$route.params.courceId;
    }
  }
}
